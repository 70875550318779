export const translations = {
  en: {
    nav: [
      "En",
      "About Us",
      "Services",
      "Contact",
    ]
  },
  ar: {
    nav: [
      "عربي",
      "حول",
      "خدمات",
      "تواصل",
    ]
  }
};
