import { createSlice } from '@reduxjs/toolkit';
import { GetAboutUsAction, GetServiceElementAction, GetServicesAction, GetSliderAction } from '../actions/LandingAction';
export const aboutUsReducer = createSlice({
    name: "Contact Reducer",
    initialState: {
        loading: false,
        about: [],
        slider: [],
        services: [],
        content: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAboutUsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAboutUsAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.about = payload.data;
            })
            .addCase(GetAboutUsAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(GetSliderAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetSliderAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.slider = payload.data;
            })
            .addCase(GetSliderAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(GetServicesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServicesAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.services = payload.data;
            })
            .addCase(GetServicesAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(GetServiceElementAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServiceElementAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.content = payload.data;
            })
            .addCase(GetServiceElementAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
    }
});
export default aboutUsReducer.reducer;
