import React, { useEffect, useState } from "react";
import { LanguageProvider } from "./context/LanguageProvider";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import Footer from "./components/footer/Footer";
import "./assets/styles/global.scss";

const App = () => {
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname === "/delete-account" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/contact" ||
      window.location.pathname === "/faq"
    ) {
      setHidden(true);
    }
  }, []);

  return (
    <LanguageProvider>
      <BrowserRouter>
        <div className="body-container ">
          {!hidden && <Navbar />}
          <AppRoutes />
          {!hidden && <Footer />}
        </div>
      </BrowserRouter>
    </LanguageProvider>
  );
};

export default App;
