import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/home/Home';
import DeleteAccount from './pages/home/delete-account/DeleteAccount';
import NotFound from './components/notfound/NotFound';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';

const AppRoutes = () => {
    return (
        <Routes basename="/">
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
        </Routes>
    );
}

export default AppRoutes;
