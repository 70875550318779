export const showSuccess = (msg, toast) => {
  toast.current.show({
    severity: "success",
    summary: "Success",
    detail: msg,
    life: 3000,
  });
};
export const showError = (msg, toast) => {
  toast.current.show({
    severity: "error",
    summary: "Error",
    detail: msg,
    life: 3000,
  });
};

export const showInfo = (msg, toast) => {
  toast.current.show({
    severity: 'info',
    summary: 'Information',
    detail: msg,
    life: 3000,
  });
}

export const showWarn = (msg, toast) => {
  toast.current.show({
    severity: "warn",
    summary: "Warning",
    detail: msg,
    life: 3000,
  });
}

export const showSticky = (title, body, toast) => {
  toast.current.show({
    severity: "info",
    summary: title,
    detail: body,
    sticky: true,
  });
}