import React, { useContext } from "react";
import { Carousel } from "primereact/carousel";
import { LangContext } from "../../context/LanguageProvider";
import { useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";
import "./Slider.scss";

const Slider = () => {
  const { lang } = useContext(LangContext);
  const { slider } = useSelector((state) => state.landing);

  const imageTemplate = (item) => {
    return (
      <div className="image-container">
        <img
          src={imageURL + item.image_path}
          alt="slider phrase"
          className="carousel-image"
        />
        <div className="middle-paragraph">
          <p className="header">
            {lang === "en" ? item.title_en : item.title_ar}
          </p>
          <p className="paragraph">
            {lang === "en" ? item.description_en : item.description_ar}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="slider">
      <Carousel
        numVisible={1}
        numScroll={1}
        showIndicators={true}
        showNavigators={false}
        value={slider}
        itemTemplate={imageTemplate}
        autoplayInterval={2000}
        circular
      />
    </div>
  );
};

export default Slider;
