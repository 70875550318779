import { createAsyncThunk } from "@reduxjs/toolkit";
import { PostContactAPI } from "../Api";
import axios from "axios";
import { errorMessage } from "../constant";

export const PostContactAction = createAsyncThunk("user/contact/post", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.post(PostContactAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
