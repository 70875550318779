import FlightIcon from "../../../assets/images/values/flight_notification_icon.png";
import TransferIcon from "../../../assets/images/values/transfer_notification.png";
import InsuranceIcon from "../../../assets/images/values/insurance_notification.png";
import HotelIcon from "../../../assets/images/values/hotel_notification.png";
import VisaIcon from "../../../assets/images/values/visa_notification.png";

export const translations = {
    en: {
        title: "Why are our services at Blue Ocean the best?",
        desc: "Experience the superior quality of Blue Ocean services.",
        values: [
            {
                icon: FlightIcon,
                title: "Flight Bookings",
                desc: "We provide Blue Ocean customers with constant support, and we never skimp on assistance and suggestions.",
                elements: [
                    "Syrian Airlines",
                    "Cham Wings Airlines",
                    "Private airlines with an online system Pegasus / Jazeera Airways Fly Dubai Fly Erbil / Fly Baghdad",
                    "All international airlines",
                ],
            },
            {
                icon: TransferIcon,
                title: "Land Trasportation",
                desc: "We depend on the best software solutions and use smart technology.",
                elements: [
                    "Car rental services within the UAE",
                    "Airport pick-up and drop-off services",
                    "Ground transportation service to and from Beirut",
                ],
            },
            {
                icon: InsuranceIcon,
                title: "Health insurance",
                desc: "We take all the client's ideas and perceptions and turn them into reality, adding our development and experience to them.",
                elements: [
                    "Health insurance",
                    "Embassies appointments",
                    "Air freight"
                ]
            },
            {
                icon: VisaIcon,
                title: "Visa and residences",
                desc: "Trust between us and our customers is first and foremost, and this is why we attribute our success.",
                elements: [
                    "Tourist visa to the Emirate",
                    "Work residency in the UAE",
                    "Residence of an investor in the UAE",
                    "Visa and residence in Iraq, Erbil-Baghdad",
                    "Approval to enter Libya",
                    "Approval to enter Egypt",
                    "Executing visas at the Saudi Consulate in Beirut",
                ],
            },
            {
                icon: HotelIcon,
                title: "Hotel Reservations",
                desc: "Blue Ocean has a young, ambitious team full of creative ideas that increase the confidence of our customers.",
                elements: [
                    "Hotel bookings in the UAE and worldwide",
                    "Online booking services",
                    "Accommodation services",
                    "Trip and schedule arrangements",
                    "Inquiries and queries",
                    "Transportation services",
                ],
            },
        ],
    },
    ar: {
        title: "لماذا خدماتنا في Blue Ocean هي الأفضل؟",
        desc: "استمتع بالجودة العالية لخدمات بلو أوشن.",
        values: [
            {
                icon: FlightIcon,
                title: "حجوزات طيران",
                desc: "نقدم لعملائنا في Blue Ocean الدعم الدائم، ونحن لا نتهاون في المساعدة والاقتراحات.",
                elements: [
                    "طيران السورية",
                    "طيران أجنحة الشام",
                    "شركة طيران الخاصة بنظام الأون لاين البيغاسوس/العربية/فلاي دبي/فلاي أربيل/فلاي بغداد",
                    "جميع شركات الطيران العالمية",
                ],
            },
            {
                icon: TransferIcon,
                title: "نقل بري",
                desc: "نعتمد على أفضل الحلول البرمجية ونستخدم التكنولوجيا الذكية.",
                elements: [
                    "خدمة تأجير سيارات داخل دولة الإمارات",
                    "خدمة توصيل واستقبال من وإلى المطارات",
                    "خدمة نقل بري من وإلى بيروت",
                ],
            },
            {
                icon: InsuranceIcon,
                title: "تأمين صحي",
                desc: "نأخذ جميع أفكار وتصورات العميل ونحولها إلى واقع، مضيفين تطويرنا وخبراتنا إليها.",
                elements: [
                    "تأمين صحي لكافة دول العالم",
                    "مواعيد السفارات",
                    "شحن جوي"
                ]
            },
            {
                icon: VisaIcon,
                title: "تأشيرات وإقامات",
                desc: "الثقة بيننا وبين عملائنا هي في المقام الأول، ولهذا نعزو نجاحنا.",
                elements: [
                    "تأشيرة سياحية إلى الإمارة",
                    "إقامة عمل في الإمارات",
                    "إقامة مستثمر في الإمارات",
                    "تأشيرة وإقامة في العراق، أربيل - بغداد",
                    "موافقة دخول ليبيا",
                    "موافقة دخول مصر",
                    "تنفيذ التأشيرات في القنصلية السعودية في بيروت",
                ],
            },
            {
                icon: HotelIcon,
                title: "حجوزات الفنادق",
                desc: "تمتلك Blue Ocean فريقًا شابًا وطموحًا مليئًا بأفكار إبداعية تزيد من ثقة عملائنا.",
                elements: [
                    "حجوزات فنادق في الإمارات والعالم",
                    "خدمات الحجز عبر الإنترنت",
                    "خدمات الاستضافة والإقامة",
                    "ترتيب الرحلات والمواعيد",
                    "الاستعلامات والاستفسارات",
                    "خدمات النقل والمواصلات",
                ],
            },
        ],
    },
};
