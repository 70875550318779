import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import "./Contact.scss";
import { GetAboutUsAction } from "../../redux/actions/LandingAction";
import { PostContactAction } from "../../redux/actions/ExtraAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showInfo } from "../../utils/ToastService";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";

const Contact = () => {
  const toast = useRef(null);
  const { about } = useSelector((state) => state.landing);
  const { loading } = useSelector((state) => state.extra);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(GetAboutUsAction());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let info = new FormData();
    info.append("email", email);
    info.append("full_name", fullName);
    info.append("question", description);

    dispatch(PostContactAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showInfo(payload.message, toast);
        window.location.reload();
      })
      .catch(({ message }) => {
        showError(message, toast);
      });
    // Handle form submission logic here
  };

  return (
    <div className="contact-distributed" id="contact">
      <Toast ref={toast} position="bottom-right" />
      <div className="contact-left">
        <h3>
          Blue Ocean <img src="./logo.png" alt="Blue Ocean" />
        </h3>
        <div className="contact-links">
          <a
            href={about.facebook}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/facebook.png")}
              alt="facebook-icon"
            />
          </a>
          <a
            href={about.instagram}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/instagram.png")}
              alt="instagram-icon"
            />
          </a>
          <a
            href={about.apple}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/apple.png")}
              alt="apple-icon"
            />
          </a>
          <a
            href={about.android}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/android.png")}
              alt="mail-icon"
            />
          </a>
        </div>

        <div className="address">
          <span>
            <i className="pi pi-phone"></i>
            {about.phone_mobile}
          </span>
          <span>
            <i className="pi pi-map-marker"></i>
            {about.address}
          </span>
        </div>
        <div className="contact-text">
          © 2024 Blue Ocean All rights reserved.
        </div>

        <a href="/" className="contact-button">
          Website
        </a>
        <div>
          <span onClick={() => navigate('/privacy-policy')} className="privacy-button all-contact-buttons">
            Privacy policy
          </span>
          <span onClick={() => navigate('/faq')} className="faq-button all-contact-buttons">
            FAQ
          </span>
        </div>
      </div>

      <div className="contact-form">
        <h4>Contact</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              maxLength={20}
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              minLength={15}
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              placeholder="Write your question here .. "
            ></textarea>
          </div>
          <Button
            loading={loading}
            type="submit"
            label="Submit"
            className="submit-button"
          ></Button>
        </form>
      </div>
      {/* <div className="footer-buttons-new">
        <div>
          
        </div>
      </div> */}
    </div>
  );
};

export default Contact;
