import { createSlice } from '@reduxjs/toolkit';
import { PostContactAction } from '../actions/ExtraAction';
export const extraReducer = createSlice({
    name: "Extra Reducer",
    initialState: {
        loading: false,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(PostContactAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(PostContactAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(PostContactAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

    }
});
export default extraReducer.reducer;
