import React, { useContext } from "react";
import { LangContext } from "../../context/LanguageProvider";
import { translations } from "./translate";
import "./Navbar.scss";

const Navbar = () => {
  const { toggle, lang } = useContext(LangContext);
  const translatedData = translations[lang].nav;
  return (
    <div className="navbar">
      <div className="menu-bar">
        <img src={"/logo.png"} alt="mudar-logo"></img>
        <ul className="list">
          <li className="toggler" onClick={toggle}>
            {translatedData[0]}
          </li>
          <a href="#aboutus">{translatedData[1]}</a>
          <a href="#services">{translatedData[2]}</a>
          <a href="#contact">{translatedData[3]}</a>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
