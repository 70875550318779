export const baseURL = 'https://it.blue-ocean.mirruba-jewellery.com/api/';
export const imageURL = 'https://it.blue-ocean.mirruba-jewellery.com/';

//About Us
export const GetAboutUsAPI = `${baseURL}client/about/get`

//Slider
export const GetSliderAPI = `${baseURL}client/slider/get?isPaginate=0`

//Services
export const GetServicesAPI = `${baseURL}client/service/get?isPaginate=0`
export const GetServiceElementsAPI = `${baseURL}client/service/get/`

export const PostContactAPI = `${baseURL}client/contact/add`
