export const homeTra = {
    en: {
        main_title: "Blue Ocean",
        sub_title: "Blue Ocean",
        desc: `Blue Ocean Foundation is a leading company in the tourism sector. We aim
        to provide our clients with various tourism services. prioritizing your
        comfort and trust. Therefore, we strive to offer everything the client
        needs in a more distinguished manner. We always seek the best by
        providing the most convenient and easy-to-use services according to the
        latest systems and with a specialized team working to meet your need
        24/7`,
    },
    ar: {
        main_title: "المحيط الأزرق",
        sub_title: "المحيط الأزرق",
        desc: ` شركة رائدة في القطاع السياحي، نعمل على تزويد عملائنا بمختلف الخدمات
        السياحية، هدفنا راحتكم وثقتكم لذا نعمل على تقديم كل مايحتاجه العميل
        بصورة أكثر تميزا، نسعى دائما للأفضل عن طريق تقديم الخدمات الأكثر
        راحة وسهولة وفق أحدث النظم وبفريق مختص يعمل على تلبية احتياجاتكم على
        مدار 24 ساعة`,
    },
};