import React, { useContext, useEffect, useRef } from "react";
import "./Values.scss";
import ValueBox from "./ValueBox";
import { LangContext } from "../../../context/LanguageProvider";
import { translations } from "./translate";
import { useSelector } from "react-redux";
import { imageURL } from "../../../redux/Api";

const Values = () => {
  const { lang } = useContext(LangContext);
  const translatedData = translations[lang];
  const sectionRef = useRef(null);
  const { services } = useSelector((state) => state.landing);

  useEffect(() => {
    const sectionRefCurrent = sectionRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const children = entry.target.children;
          const childrenArray = Array.from(children);
          childrenArray.forEach((child, index) => {
            setTimeout(() => {
              child.classList.add("animate__fadeInUp");
            }, index * 150);
          });
        } else {
          const children = entry.target.children;
          const childrenArray = Array.from(children);
          childrenArray.forEach((child) => {
            child.classList.remove("animate__fadeInUp");
          });
        }
      });
    });

    if (sectionRefCurrent) {
      observer.observe(sectionRefCurrent);
    }

    return () => {
      if (sectionRefCurrent) {
        observer.unobserve(sectionRefCurrent);
        const children = sectionRefCurrent.children;
        const childrenArray = Array.from(children);
        childrenArray.forEach((child) => {
          child.classList.remove("animate__pulse", "animate__repeat-2");
        });
      }
    };
  }, [sectionRef]);
  return (
    <div className="values" id="services">
      <div className="header">
        <div className="title">{translatedData.title}</div>
        <div className="desc">{translatedData.desc} </div>
      </div>
      <div className="grid">
        <div className="grid-container" ref={sectionRef}>
          {services.map((item, index) => (
            <ValueBox
              key={index}
              icon={imageURL + item.image_path}
              desc={lang === "en" ? item.description_en : item.description_ar}
              title={lang === "en" ? item.title_en : item.title_ar}
              id={item.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Values;
