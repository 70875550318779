import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './Faq.scss';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const faqs = [
    {
      question: 'What is Blue Ocean?',
      answer: 'Blue Ocean Foundation is a leading company in the tourism sector. We aim to provide our clients with various tourism services. prioritizing your comfort and trust. Therefore, we strive to offer everything the client needs in a more distinguished manner. We always seek the best by providing the most convenient and easy-to-use services according to the latest systems and with a specialized team working to meet your need 24/7.',
    },
    {
      question: 'Who can use Blue Ocean?',
      answer: 'You can use Blue Ocean on smartphones, tablets, and even computers. We have Apps for IOS and Android.'
    },
    {
      question: 'Where is Blue Ocean location?',
      answer: 'UAE , Dubai.',
    },
    {
      question: 'How old is Blue Ocean?',
      answer: 'Since 2023',
    },
    // Add more questions and answers here
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const filteredFAQs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="faq-container">
      <div className="return-button" onClick={() => navigate('/contact')}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <h2>Frequently Asked Questions</h2>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="faq-search"
      />
      {filteredFAQs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
