import React from "react";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { clickButton } from "../../../redux/buttonSlice";
import { GetServiceElementAction } from "../../../redux/actions/LandingAction";

const ValueBox = ({ id, icon, title, desc }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(GetServiceElementAction(id)).then(({ payload }) => {
      const data = payload.data;
      console.log(data);
      dispatch(
        clickButton({ status: true, data: data, icon: icon, title: title })
      );
    });
  };
  return (
    <div className="box animate__animated">
      <div className="icon">
        <img src={icon} alt="title"></img>
      </div>
      <div className="title">{title}</div>
      <p className="desc">{desc}</p>
      <Button
        severity="success"
        text
        raised
        icon="pi pi-eye"
        onClick={handleClick}
      />
    </div>
  );
};

export default ValueBox;
