import React, { useRef, useState } from "react";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { countryCodes } from "./CountryCodes";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import "./DeleteAccount.scss";

function DeleteAccount() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [verificationCodeInputVisible, setVerificationCodeInputVisible] =
    useState(false);
  const [deleteAccountVisible, setDeleteAccountVisible] = useState(false);
  const show = (msg, severity) => {
    toast.current.show({
      severity: severity,
      summary: "Info",
      detail: msg,
    });
  };

  const options = Object.keys(countryCodes).map((key) => ({
    value: countryCodes[key].code,
    label: `${countryCodes[key].name} (+${countryCodes[key].code})`,
    icon: `fi fi-${key.toLowerCase()}`,
  }));

  const sendVerificationCode = () => {
    setLoading(true);
    let phone = phoneNumber;
    if (phoneNumber.startsWith("0")) {
      phone = phoneNumber.substring(1);
    }
    axios
      .get(
        `https://blueocean.sy/blue-ocean-back-end/public/index.php/api/v1/client/verify/code?phone=${
          selectedCountry + phone
        }`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          setVerificationCodeInputVisible(true);
          show(response.data.message, "success");
        } else {
          show(response.message, "error");
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response);
        show(response.data.message, "error");
        setLoading(false);
      });
  };

  const verifyCode = () => {
    setLoading(true);
    let phone = phoneNumber;
    if (phoneNumber.startsWith("0")) {
      phone = phoneNumber.substring(1);
    }
    axios
      .post(
        `https://blueocean.sy/blue-ocean-back-end/public/index.php/api/v1/client/verify/code/check?code=${verificationCode}&phone=${
          selectedCountry + phone
        }`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          show(response.data.message, "success");
          localStorage.setItem("user_token", response.data.data.access_token);
          setDeleteAccountVisible(true);
        } else {
          show(response.message, "error");
        }
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response);
        show(response.data.message, "error");
        setLoading(false);
      });
  };

  const deleteAccount = () => {
    setLoading(true);

    axios
      .post(
        "https://blueocean.sy/blue-ocean-back-end/public/index.php/api/v1/client/delete",
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status) {
          show(response.data.message, "success");
          window.location.reload();
        } else {
          show(response.data.message, "error");
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting account:", error);
        setLoading(false);
      });
  };

  return (
    <div className="delete-account">
      <Toast ref={toast} />
      <div id="phoneForm">
        <div className="header">
          <img src="./logo.png" alt="blue-ocean" />
          <h2 style={{ textAlign: "center" }}>Phone Verification</h2>
        </div>

        <div className="p-inputgroup">
          <Dropdown
            placeholder="Country"
            filter
            appendTo={"self"}
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.value)}
            options={options}
            itemTemplate={(e) => {
              return (
                <div className="country-item">
                  <i className={e.icon}></i>
                  <h6>{e.label}</h6>
                </div>
              );
            }}
          />
          <input
            type="tel"
            id="phoneNumber"
            placeholder="Enter phone number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>

        {!verificationCodeInputVisible && (
          <Button
            onClick={sendVerificationCode}
            disabled={loading || phoneNumber.length === 0 || !selectedCountry}
            severity="info"
            label="Confirm"
          ></Button>
        )}
        {verificationCodeInputVisible && (
          <div id="verificationCodeInput">
            <input
              maxLength={4}
              type="text"
              id="verificationCode"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            {!deleteAccountVisible && (
              <Button
                onClick={verifyCode}
                disabled={loading}
                label="Verify"
              ></Button>
            )}
          </div>
        )}
        {deleteAccountVisible && (
          <div className="delete-action">
            <h3>Are you sure you want to delete your account?</h3>
            <div className="actions">
              <Button
                disabled={loading}
                onClick={deleteAccount}
                severity="success"
                label="Yes"
              ></Button>
              <Button
                disabled={loading}
                severity="danger"
                label="No"
                onClick={() => {
                  window.location.reload();
                }}
              ></Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteAccount;
