import React, { useContext, useEffect } from "react";
import Values from "./values/Values";
import Landing from "./landing/Landing";
import "./Home.scss";
import InformationPopup from "../../components/popup-model/InformationPopup";
import { homeTra } from "./translate";
import { LangContext } from "../../context/LanguageProvider";
import { useDispatch } from "react-redux";
import {
  GetAboutUsAction,
  GetServicesAction,
  GetSliderAction,
} from "../../redux/actions/LandingAction";

const Home = () => {
  const dispatch = useDispatch();
  const { lang } = useContext(LangContext);
  const data = homeTra[lang];
  useEffect(() => {
    dispatch(GetAboutUsAction());
    dispatch(GetSliderAction());
    dispatch(GetServicesAction());
  }, [dispatch]);

  return (
    <div className="home" id="aboutus">
      <div className="heading">
        <div className="title">{data.sub_title}</div>
      </div>
      <Landing />
      <div className="overlay">
        <div className="container transparent">
          <Values />
        </div>
      </div>
      <InformationPopup />
    </div>
  );
};

export default Home;
