import { configureStore } from '@reduxjs/toolkit';
import buttonReducer from './buttonSlice';
import LandingReducer from './reducers/LandingReducer';
import ExtraReducer from './reducers/ExtraReducer';

export default configureStore({
    reducer: {
        button: buttonReducer,
        landing: LandingReducer,
        extra: ExtraReducer,
    },
});
