import React, { useContext } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { clickButton } from "../../redux/buttonSlice";
import { LangContext } from "../../context/LanguageProvider";
import "./InformationPopup.scss";

const InformationPopup = () => {
  const { isClicked, data, title, icon } = useSelector((state) => state.button);
  const { lang } = useContext(LangContext);
  const dispatch = useDispatch();
  const header = (
    <div className="header">
      <img src={icon} alt="blue-ocean-icon" width={100} />
      <h2>{title}</h2>
    </div>
  );

  return (
    <Dialog
      appendTo={"self"}
      className="information-popup"
      header={header}
      dismissableMask
      closable={false}
      visible={isClicked}
      onHide={() => {
        dispatch(clickButton({ status: false, data: [] }));
      }}
    >
      <div className="content">
        <ul className="list">
          {data?.map((item, index) => {
            return (
              <li key={index}>
                {lang === "en" ? item.description_en : item.description_ar}
              </li>
            );
          })}
        </ul>
      </div>
    </Dialog>
  );
};

export default InformationPopup;
