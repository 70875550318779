import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isClicked: false,
    data: [],
    title: "",
    icon: ""
};

export const buttonSlice = createSlice({
    name: 'button',
    initialState,
    reducers: {
        clickButton: (state, { payload }) => {
            state.isClicked = payload.status;
            state.data = payload.data;
            state.title = payload.title;
            state.icon = payload.icon;
        },
    },
});

export const { clickButton } = buttonSlice.actions;

export const selectIsClicked = (state) => state.button.isClicked;
export const selectData = (state) => state.button.data;

export default buttonSlice.reducer;
