import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAboutUsAPI, GetServiceElementsAPI, GetServicesAPI, GetSliderAPI } from "../Api";
import axios from "axios";
import { errorMessage } from "../constant";

export const GetAboutUsAction = createAsyncThunk("user/about-us/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetAboutUsAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetSliderAction = createAsyncThunk("user/slider/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetSliderAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetServicesAction = createAsyncThunk("user/services/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetServicesAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetServiceElementAction = createAsyncThunk("service/content/get", async (id, { rejectWithValue }) => {
    try {
        let { data } = await axios.get(GetServiceElementsAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});