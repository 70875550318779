import { createContext, useState } from "react";

export const LangContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState("en");
    const toggle = () => {
        setLang((prev) => (prev === "en" ? "ar" : "en"));
    };
    return (
        <LangContext.Provider value={{ toggle, lang }}>
            <div className={`lang ${lang}`}>{children}</div>
        </LangContext.Provider>
    );
};