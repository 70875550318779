import React from "react";
import './PrivacyPolicy.scss'
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div style={{ direction: "rtl", margin: "20px" }}>
      <div className="return-button" onClick={() => navigate('/contact')}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </div>
      <h1>سياسة الاستخدام والخصوصية لتطبيق Blue Ocean</h1>
      <p>
        تهدف هذه الوثيقة إلى توضيح كيفية جمعنا واستخدامنا للبيانات الشخصية،
        ومشاركتها، بالإضافة إلى الخيارات المتاحة لك فيما يتعلق بهذه البيانات.
        نوصيك بقراءة هذه الوثيقة التي تتضمن الممارسات الرئيسية للخصوصية.
      </p>

      <h2>التعريفات والمقدمات</h2>
      <p>
        شركة Blue Ocean المحدودة المسؤولية هي شركة مسجلة في السجل التجاري تحت
        الرقم xxxx من xxxx، وتقدم الخدمات المتاحة على التطبيق. يُعتبر التطبيق
        منصة متكاملة تقدم خدمات الرحلات البرية والجوية والتأمين الصحي وتأشيرات
        السفر وحجوزات الفنادق. يهدف التطبيق إلى تسهيل عملية الحجز وتوفير معلومات
        شاملة للمستخدمين حول الرحلات والتأمين والتأشيرات والفنادق المتاحة. يعمل
        التطبيق على أجهزة iOS و Android وتعمل الشركة على تحديثه بشكل منتظم لضمان
        استمرارية عمل التطبيق.
      </p>

      <h2>الفئات المستهدفة</h2>
      <p>
        التطبيق يستهدف مجموعة واسعة من المستخدمين والعملاء الذين يبحثون عن خدمات
        السفر والتأمين وتأشيرات السفر وحجوزات الفنادق. يمكن أن يكون المستخدمون
        من مختلف الفئات العمرية والجنسيات والمهن. يشير مصطلح "المستخدمين" إلى
        الأشخاص الذين يقومون بطلب خدمة من خدماتنا، سواءً كانوا يقومون بالطلب
        بأنفسهم أو من خلال شخص آخر.
      </p>

      <h2>نظرة عامة</h2>
      <p>
        تنطبق هذه الوثيقة على جميع مستخدمي تطبيق Blue Ocean. تضبط هذه الوثيقة
        عمليات جمع البيانات الشخصية التي يتم جمعها بواسطة التطبيق، وذلك فيما
        يتعلق بالخدمات المتاحة على التطبيق.
      </p>

      <h2>البيانات التي يجمعها الشركة</h2>
      <p>
        تجمع الشركة البيانات التالية:
        <ol>
          <li>
            البيانات المقدمة من قبل المستخدمين: تشمل المعلومات التي يتم إدخالها
            أثناء إنشاء الحساب، والبيانات التي تم إنشاؤها أثناء استخدام خدماتنا،
            مثل بيانات الموقع وبيانات استخدام التطبيق وبيانات الجهاز.
          </li>
          <li>
            البيانات المقدمة من قبل المستخدمين: يتم جمع البيانات المقدمة من قبل
            المستخدمين، ويشمل ذلك الملف التعريفي للمستخدم نقوم بجمع بيانات عندما
            ينشئ المستخدمون حسابات في الشركة أو يقومون بتحديثها، ويتمكن المستخدم
            إلى الاطلاع على سياسة الاستخدام والخصوصية الفعلية لتطبيق Blue Ocean
            لتحصل على المعلومات الدقيقة والمحدثة بشأن جمع البيانات الشخصية
            وسياسات الخصوصية المتبعة.
          </li>
        </ol>
      </p>

      <h2>كيفية استخدام الشركة للبيانات</h2>
      <p>
        تجمع الشركة البيانات وتستخدمها لتوفير الخدمات بشكل ملائم وآمن. وتستخدم
        البيانات التي نجمعها في الأغراض التالية:
        <ol>
          <li>
            السلامة والأمان: نستخدم البيانات الشخصية للمساعدة في الحفاظ على
            سلامة وأمان خدماتنا والمستخدمين.
          </li>
          <li>
            لا تشارك الشركة بيانات المستخدمين الشخصية مثل أرقام الهواتف مع
            الجهات الخارجية.
          </li>
          <li>
            التسويق: يحق للشركة استخدام البيانات التي نجمعها للتسويق لخدماتنا
            للمستخدمين، بما في ذلك التسويق لميزات الشركة وعروضها الترويجية
            والتحديثات.
          </li>
          <li>
            الإجراءات والمتطلبات القانونية: يحق لنا استخدام البيانات الشخصية مثل
            أرقام الهواتف التي نجمعها للتحقيق في المطالبات أو النزاعات المتعلقة
            باستخدام خدمات الشركة وحلها، وفقًا للقانون أو بناءً على طلب من
            الجهات التنظيمية والحكومية والاستفسارات الرسمية.
          </li>
        </ol>
      </p>

      <h2>حقوق وواجبات المستخدمين</h2>
      <p>
        <ol>
          <li>
            خصوصية المعلومات الشخصية الخاصة به(يتم استخدام تقنيات التشفير لحماية
            المعلومات الشخصية بالإضافة الى استخدام بروتوكول آمن (HTTPS) وايضا
            توعية الموظفين بأهمية الخصوصية وحقوق المستخدمين وايضاً البيانات غير
            قابلة للوصول غير المصرح به).
          </li>
          <li>القدرة على الوصول إلى المعلومات التي تم جمعها عنه.</li>
          <li>القدرة على التحكم بمعلوماته الشخصية.</li>
          <li>القدرة حذف الحساب الشخصي.</li>
          <li>
            تقييم الخدمات تحتفظ الشركة بحق حظر حسابات المستخدمين الذين يسيئون
            استخدام الخدمة، مثل تقديم طلبات مزيفة بهدف التسلية.
          </li>
        </ol>
      </p>

      <h2>تحديثات هذه الوثيقة</h2>
      <p>
        يجوز لنا تحديث هذه الوثيقة من حين لآخر. ويعدُّ استخدام خدماتنا بعد
        تحديثها موافقة على الإشعار المُحدَّث.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
