import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import "./Footer.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const { about } = useSelector((state) => state.landing);

  return (
    <footer className="footer-distributed" id="contact">
      <Toast ref={toast} position="bottom-right" />
      <div className="footer-left">
        <h3>
          Blue Ocean <img src="./logo.png" alt="Blue Ocean" />
        </h3>
        <div className="footer-links">
          <a
            href={about.facebook}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/facebook.png")}
              alt="facebook-icon"
            />
          </a>
          <a
            href={about.instagram}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/instagram.png")}
              alt="instagram-icon"
            />
          </a>
          <a
            href={about.apple}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/apple.png")}
              alt="apple-icon"
            />
          </a>
          <a
            href={about.android}
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require("./../../assets/images/social-media/android.png")}
              alt="mail-icon"
            />
          </a>
        </div>

        <div className="address">
          <span>
            <i className="pi pi-phone"></i>
            {about.phone_mobile}
          </span>
          <span>
            <i className="pi pi-map-marker"></i>
            {about.address}
          </span>
        </div>
        <div className="footer-text">
          © 2024 Blue Ocean All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
