import React, { useContext } from "react";
import { LangContext } from "../../../context/LanguageProvider";
import Slider from "../../../components/slider/Slider";
import "./Landing.scss";
// import { homeTra } from "../translate";
import { useSelector } from "react-redux";

const Landing = () => {
  const { lang } = useContext(LangContext);
  // const data = homeTra[lang];
  const { about } = useSelector((state) => state.landing);

  return (
    <div className="banner">
      <div className="banner-container transparent">
        <div className="bio">
          <Slider />
          <p className="desc">
            {lang === "en"
              ? about.main_description_en
              : about.main_description_ar}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
